import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthWrapper from "wrappers/AuthWrapper";
import { useAuth } from "utils/hooks/useAuth";

const Login = lazy(() => import("pages/Login"));
const Dashboard = lazy(() => import("pages/Dashboard"));

export default function RootWrapper() {
  const authContext = useAuth();
  return (
    <Routes>
      <Route
        path="/login"
        element={authContext?.token ? <Navigate to="/" replace /> : <Login />}
      />
      <Route path="/" element={<AuthWrapper />}>
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>
    </Routes>
  );
}
