import { Button } from "antd";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { ButtonType } from "constants/enums";

interface IProps {
  title?: string;
  icon?: any;
  className?: string | any;
  onClick?: () => void;
  isLoading?: boolean;
  type?: ButtonType;
  disabled?: boolean;
  style?: any;
}

export default function CustomButton({
  title,
  icon,
  className,
  onClick,
  type,
  isLoading,
  disabled,
  style,
}: IProps) {
  return (
    <div
      className={classNames(
        styles.btnCustom,
        {
          [styles.btnOutline]: type === ButtonType.OUTLINE,
          [styles.btnAction]: type === ButtonType.ACTION,
          [styles.btnAction]: type === ButtonType.ACTION,
          [styles.btnNoneRound]: type === ButtonType.NONE_ROUND,
        },
        className
      )}
    >
      <Button
        icon={icon}
        onClick={onClick}
        loading={isLoading}
        disabled={disabled}
        style={style}
      >
        {title}
      </Button>
    </div>
  );
}
