import { Dropdown } from "antd";
import { ELocalStorageKey } from "constants/enums";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import {
  IconArrowDown,
  IconLock,
  IconUserHeader,
  IconLogout,
} from "assets/icon";
import { useRole } from "utils/hooks/usePermissions";
import { useState } from "react";
import ModalEditSuccess from "components/ModalEditSuccess";
import { images } from "assets";
import { useAuth } from "utils/hooks/useAuth";

export default function PageHeader() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const role = useRole();
  const authContext = useAuth();

  const [openModalEditSuccess, setOpenModalEditSuccess] =
    useState<boolean>(false);
  const [openModalChangePassword, setOpenModalChangePassword] =
    useState<boolean>(false);

  const onLogOut = (info?: any) => {
    authContext?.logout();
  };

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={onLogOut}
          className={styles.viewBtnAction__changePassword}
        >
          <IconLogout className={styles.iconAction__iconLock} />
          {t("common.logout")}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.viewNameStore}>
        {/* <img src={images.logoAmela} className={styles.logoApp} /> */}
        <div>{t("common.webName")}</div>
      </div>
      <div className={styles.actions}>
        <div className={styles.menuWrapper}>
          <div className={styles.menuItem}>
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              overlayClassName={styles.dropdown}
              className={styles.dropdownOpen}
            >
              <div className={styles.interfaceDropdown}>
                <IconUserHeader />
                <span className={styles.nameAccount}>
                  {localStorage.getItem(ELocalStorageKey.USERNAME) || "Admin"}
                </span>
                <IconArrowDown />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <ModalEditSuccess
        title="パスワード変更完了"
        content="パスワードの変更が完了しました"
        open={openModalEditSuccess}
        toggle={() => {
          setOpenModalEditSuccess(!openModalEditSuccess);
        }}
        onSubmit={() => setOpenModalEditSuccess(false)}
      />
    </div>
  );
}
